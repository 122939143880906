@import '../../styles/colors.scss';

.splashscreen {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    max-width: 800px;
    margin: 0 auto;

    h1 {
        max-width: 90%;
        margin: 0 auto;
        padding: 45px 30px 8px 30px;
        color: $kleur-primair-abp-blauw;
    }
    h2 {
        max-width: 90%;
        margin: 0 auto 45px auto;
        padding: 0 30px;
        color: $kleur-primair-abp-petrol;
    }
    button {
        margin: 0 auto;
    }
}

@media (max-width: 600px) {
    .splashscreen {
        h1 {
            font-size: 45px;
        }
        h2 {
            font-size: 21px;
        }
    }
}

@media (max-width: 400px) {
    .splashscreen {
        h1 {
            font-size: 36px;
        }
        h2 {
            font-size: 18px;
        }
    }
}

@media (max-height: 600px) {
    .splashscreen {
        h1 {
            font-size: 36px;
        }
        h2 {
            font-size: 18px;
        }
    }
}
