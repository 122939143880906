@import '../../styles/colors.scss';

// http://lynn.io/2014/02/22/modalin/

.modal {
    pointer-events: none;

    .overlay {
        z-index: 100;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        min-height: 100vh;
        background-color: #333;
        transition: opacity 0.3s ease;
    }

    .panel {
        z-index: 101;
        max-height: 0;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 3px 3px 36px 3px;
        overflow: hidden;
        max-width: 0;
        transition: all 0.3s ease;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);

        .title-bar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h3 {
                margin: 0;
                padding: 24px 24px 12px 24px;
                color: $kleur-primair-abp-blauw;
            }
    
            .kruis {
                padding: 18px 18px 0px 0px;
                width: 45px;
                height: 45px;
                stroke: $grijstint-zwartgrijs;
                cursor: pointer;

                line {
                    transition: all 0.3s ease;
                    transform-origin: 50% 50%;
                }
            }
            .kruis:hover {
                .lijn1 {
                    transform: rotate(45deg)
                }
                .lijn2 {
                    transform: rotate(-45deg)
                }
            }
        }

        .content {
            padding: 24px;
        }
        p {
            margin: 0;
            padding: 0 0 12px 0;
            color: $grijstint-zwartgrijs;
        }
        a {
            color: $kleur-primair-abp-blauw;
            font-weight: 600;
        }
    }
}

.modal.open {
    pointer-events: all;
    .overlay {
        opacity: 0.8;
    }
    .panel {
        max-height: calc(100% - 100px);
        max-width: 100%;
        overflow-y: auto;
    }
}


@media (max-width: 720px) {
    .modal {
        .panel {
            min-width: 90%;
            h3 {
                font-size: 18px;
            }
            p {
               font-size: 15px;
            }
        }

    }
}
