@import '../../styles/colors.scss';

.cardscreen {
    margin-bottom: 48px;

    .page-title {
        color: $kleur-primair-abp-blauw;
        margin: 0 0 48px 0;
        text-align: center;
    }
    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 48px;
    }
}

@media (max-width: 920px) {
    .cardscreen {
        .page-title {
            margin: 0 45px 48px 45px;
        }
    }
}

@media (max-width: 720px) {
    .cardscreen {
        .page-title {
            text-align: left;
            font-size: 24px;
            margin: 0 45px 48px 0;
        }
    }
}
