@import '../../styles/colors.scss';
.optionscreen {
    margin-bottom: 48px;

    .page-title {
        color: $kleur-primair-abp-blauw;
        padding-bottom: 12px;
        padding-right: 45px;
    }
    h6 {
        margin: 0;
        padding-right: 45px;
    }
    .go-right {
        display: flex;
        justify-content: flex-end;
    }
}
