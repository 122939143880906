@import '../../styles/colors.scss';

.steeg {
    .radio {
        background: transparent;
        border-radius: 100%;
        border: 2px solid $grijstint-zwartgrijs;
        display: block;
        margin: 0 auto;
        position: relative;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
        width: 15px;
        height: 15px;
        pointer-events: none;
    }
    .radio.checked{
        background-color: $grijstint-zwartgrijs;
        box-shadow: inset 0 0 0 2px $grijstint-witgrijs;
    }
    .radio:focus {
        outline: none;
        border-color: $grijstint-zwartgrijs;
    }
}
