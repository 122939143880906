@import '../../styles/colors.scss';

.straatresult {
    table {
        margin: 0;
        border-collapse: collapse;
        width: 100%;

        tr {
            td:first-of-type {
                padding: 0 12px 0 30px;
            }
        }
        thead {
            tr {
                height: 45px;
                td {
                    vertical-align: bottom;
                }
                h6 {
                    margin: 0;
                    padding: 12px 30px 6px 0;
                }
                .label {
                    padding-bottom: 6px;
                }
            }
        }
        tbody {
            tr {
                height: 30px;
            }
        }
    }
}

@media (max-width: 400px) {
    .straatresult {
        table {
            tr {
                td:first-of-type {
                    padding: 6px 12px 6px 12px;
                }
            }
        }
    }
}
