@import url(https://fonts.googleapis.com/css?family=Open+Sans);
button {
  padding: 8px 24px;
  border-radius: 2px 2px 8px 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18.66px;
  line-height: 130%;
  -webkit-font-feature-settings: 'pnum' on, 'lnum' on;
          font-feature-settings: 'pnum' on, 'lnum' on; }
  button .icon {
    width: 16px;
    height: 16px; }

.button-primary {
  position: relative;
  -webkit-appearance: none;
  background: #ED7102;
  border: 1px solid #ED7102;
  color: #fff;
  cursor: pointer;
  box-sizing: content-box; }
  .button-primary .mail polyline {
    stroke: #ED7102; }
  .button-primary .print .lade {
    stroke: #ED7102; }

.button-primary:hover {
  background: #A7358B;
  border: 1px solid #A7358B; }
  .button-primary:hover .mail polyline {
    stroke: #A7358B; }
  .button-primary:hover .print .lade {
    stroke: #A7358B; }

.button-secondary {
  position: relative;
  -webkit-appearance: none;
  background: #009CBD;
  border: 1px solid #009CBD;
  color: #fff;
  cursor: pointer;
  box-sizing: content-box;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.button-secondary:hover {
  background: #0057A3;
  border: 1px solid #0057A3;
  color: #FFFFFF; }
  .button-secondary:hover svg polyline {
    stroke: #FFFFFF; }

.button-primary-ghost {
  position: relative;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #ED7102;
  color: #000000;
  cursor: pointer;
  box-sizing: content-box;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.button-primary-ghost:hover {
  background: #A7358B;
  border: 1px solid #A7358B;
  color: #FFFFFF; }
  .button-primary-ghost:hover svg polyline {
    stroke: #FFFFFF; }

.button-secondary-ghost {
  position: relative;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #009CBD;
  color: #000000;
  cursor: pointer;
  box-sizing: content-box;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.button-secondary-ghost:hover {
  background: #0057A3;
  border: 1px solid #0057A3;
  color: #FFFFFF; }
  .button-secondary-ghost:hover svg polyline {
    stroke: #FFFFFF; }

button.right {
  padding: 8px 16px 8px 24px; }
  button.right .pijl {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

button.left {
  padding: 8px 24px 8px 16px;
  flex-direction: row-reverse !important; }
  button.left .pijl {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

button.greyedOut {
  opacity: 0.5;
  pointer-events: none; }

button.icon-flipped svg {
  -webkit-transform: none !important;
          transform: none !important; }

.splashscreen {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  max-width: 800px;
  margin: 0 auto; }
  .splashscreen h1 {
    max-width: 90%;
    margin: 0 auto;
    padding: 45px 30px 8px 30px;
    color: #0057A3; }
  .splashscreen h2 {
    max-width: 90%;
    margin: 0 auto 45px auto;
    padding: 0 30px;
    color: #009CBD; }
  .splashscreen button {
    margin: 0 auto; }

@media (max-width: 600px) {
  .splashscreen h1 {
    font-size: 45px; }
  .splashscreen h2 {
    font-size: 21px; } }

@media (max-width: 400px) {
  .splashscreen h1 {
    font-size: 36px; }
  .splashscreen h2 {
    font-size: 18px; } }

@media (max-height: 600px) {
  .splashscreen h1 {
    font-size: 36px; }
  .splashscreen h2 {
    font-size: 18px; } }

.header {
  position: fixed;
  min-height: 100px;
  z-index: 2;
  top: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.03);
  border-radius: 0px 0px 32px 4px;
  display: grid;
  grid-template-columns: 60px 160px auto 60px;
  grid-template-rows: 100px 12px; }
  .header a {
    text-decoration: none;
    align-self: center; }
  .header .title {
    text-align: center;
    padding: 0;
    color: #000000;
    margin-right: 160px; }
  .header .backbutton {
    width: 60px;
    cursor: pointer;
    align-self: center; }
    .header .backbutton .pijl {
      width: 60px;
      height: 60px; }
  .header .logo-abp {
    fill: #fff;
    height: 48px;
    width: 160px;
    align-self: center; }
  .header .infobutton {
    width: 60px;
    cursor: pointer;
    align-self: center; }
    .header .infobutton .info {
      width: 60px;
      height: 60px;
      fill: #009CBD; }
  .header .icon {
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .header .icon:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  .header .stepper {
    width: 100%;
    height: 12px;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 2;
    background-color: #E2E5E9;
    background: linear-gradient(90deg, #e2e5e9 0%, #e2e5e9 66%, rgba(255, 255, 255, 0) 100%); }
    .header .stepper .step {
      float: left;
      width: 33.333333%;
      height: 12px;
      background-color: #CCEBF1;
      border-right: 1px solid #ACAEB0;
      box-sizing: border-box; }
    .header .stepper .step:last-of-type {
      border-right: none; }
    .header .stepper .last {
      background-color: #CCEBF1;
      background: linear-gradient(90deg, #ccebf1 0%, rgba(255, 255, 255, 0) 100%); }

@media (max-width: 700px) {
  .header {
    grid-template-columns: 36px 80px auto 36px; }
    .header .title {
      margin-right: 80px; }
    .header h2 {
      margin-top: 21px;
      font-size: 18px; }
    .header .logo-abp {
      height: 24px;
      width: 80px; }
    .header .backbutton {
      width: 36px; }
      .header .backbutton .pijl {
        width: 36px;
        height: 36px; }
    .header .infobutton {
      width: 36px; }
      .header .infobutton .info {
        width: 36px;
        height: 36px; } }

@media (max-width: 430px) {
  .header {
    grid-template-columns: 36px 60px auto 36px; }
    .header .title {
      margin-right: 60px; }
    .header h2 {
      margin-top: 24px;
      font-size: 15px; }
    .header .logo-abp {
      height: 12px;
      width: 60px; } }

.sharebuttons {
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 168px;
  right: -186px;
  height: 45px;
  background-color: #009CBD;
  border-radius: 6px 0 0 6px;
  z-index: 3;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2); }
  .sharebuttons span {
    display: inline-block;
    width: 45px;
    height: 45px;
    cursor: pointer; }
  .sharebuttons .mail rect {
    fill: #009CBD; }
  .sharebuttons .mail polyline {
    fill: #009CBD;
    stroke: #fff;
    stroke-width: 2px; }
  .sharebuttons .socialbutton {
    background-color: #fff;
    border-left: 1px solid #009CBD; }
    .sharebuttons .socialbutton .icon {
      transition: all 0.1s ease-in-out; }
  .sharebuttons .socialbutton:hover .icon {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.sharebuttons.open {
  right: 0px; }

.modal {
  pointer-events: none; }
  .modal .overlay {
    z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    min-height: 100vh;
    background-color: #333;
    transition: opacity 0.3s ease; }
  .modal .panel {
    z-index: 101;
    max-height: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 3px 3px 36px 3px;
    overflow: hidden;
    max-width: 0;
    transition: all 0.3s ease;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2); }
    .modal .panel .title-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .modal .panel .title-bar h3 {
        margin: 0;
        padding: 24px 24px 12px 24px;
        color: #0057A3; }
      .modal .panel .title-bar .kruis {
        padding: 18px 18px 0px 0px;
        width: 45px;
        height: 45px;
        stroke: #282D32;
        cursor: pointer; }
        .modal .panel .title-bar .kruis line {
          transition: all 0.3s ease;
          -webkit-transform-origin: 50% 50%;
                  transform-origin: 50% 50%; }
      .modal .panel .title-bar .kruis:hover .lijn1 {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .modal .panel .title-bar .kruis:hover .lijn2 {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .modal .panel .content {
      padding: 24px; }
    .modal .panel p {
      margin: 0;
      padding: 0 0 12px 0;
      color: #282D32; }
    .modal .panel a {
      color: #0057A3;
      font-weight: 600; }

.modal.open {
  pointer-events: all; }
  .modal.open .overlay {
    opacity: 0.8; }
  .modal.open .panel {
    max-height: calc(100% - 100px);
    max-width: 100%;
    overflow-y: auto; }

@media (max-width: 720px) {
  .modal .panel {
    min-width: 90%; }
    .modal .panel h3 {
      font-size: 18px; }
    .modal .panel p {
      font-size: 15px; } }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 240px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px 3px 36px 3px;
  cursor: pointer;
  text-align: center; }
  .card .card-title {
    min-height: 48px;
    padding: 24px;
    margin: 0;
    color: #0057A3; }
  .card p {
    margin: 0 24px;
    color: #282D32;
    height: 100%; }
  .card button {
    margin-bottom: 24px; }

@media (max-width: 430px) {
  .card {
    width: 100%;
    height: auto; }
    .card .card-title {
      min-height: unset; }
    .card p {
      margin-bottom: 24px; } }

.cardscreen {
  margin-bottom: 48px; }
  .cardscreen .page-title {
    color: #0057A3;
    margin: 0 0 48px 0;
    text-align: center; }
  .cardscreen .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 48px;
    gap: 48px; }

@media (max-width: 920px) {
  .cardscreen .page-title {
    margin: 0 45px 48px 45px; } }

@media (max-width: 720px) {
  .cardscreen .page-title {
    text-align: left;
    font-size: 24px;
    margin: 0 45px 48px 0; } }

.steeg .radio {
  background: transparent;
  border-radius: 100%;
  border: 2px solid #282D32;
  display: block;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  width: 15px;
  height: 15px;
  pointer-events: none; }

.steeg .radio.checked {
  background-color: #282D32;
  box-shadow: inset 0 0 0 2px #EDF1F2; }

.steeg .radio:focus {
  outline: none;
  border-color: #282D32; }

.straat {
  color: #000000; }
  .straat table {
    margin: 0;
    border-collapse: collapse;
    width: 100%; }
    .straat table tr {
      height: 80px;
      overflow: hidden; }
      .straat table tr td:first-of-type {
        padding: 6px 12px 6px 30px; }
    .straat table thead tr td {
      vertical-align: middle; }
    .straat table thead tr h6 {
      margin: 0;
      padding: 12px 30px 6px 0; }
    .straat table thead tr .label {
      padding: 6px 0; }
    .straat table tbody {
      background-color: #FFFFFF; }
      .straat table tbody tr td {
        border-top: 1px solid #E2E5E9;
        border-bottom: 1px solid #E2E5E9; }
      .straat table tbody tr:last-of-type td {
        border-bottom: none; }
    .straat table .label {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      width: 100px;
      position: relative; }
    .straat table label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0px;
      right: 0px;
      width: 100%;
      height: 80px; }
      .straat table label input[type="radio"] {
        opacity: 0;
        padding: 15px; }

@media (max-width: 720px) {
  .straat table tr td:first-of-type {
    padding: 6px 0px 6px 12px;
    width: 50%; }
  .straat table thead tr h6 {
    padding: 12px 0 6px 0; }
  .straat table .label {
    font-size: 14px; } }

@media (max-width: 400px) {
  .straat table thead tr h6 {
    font-size: 14px; }
  .straat table .label {
    font-size: 14px; }
  .straat table td {
    font-size: 14px; } }

.wijk {
  background-color: #fff;
  border-radius: 3px 3px 36px 3px;
  border-left: 30px solid #282D32;
  margin: 48px 0 0 0;
  color: #282D32;
  overflow: hidden; }
  .wijk .wijk-naam {
    margin: 0;
    padding: 12px 30px; }

.wijk:last-of-type {
  margin-bottom: 48px; }

@media (max-width: 720px) {
  .wijk {
    border-left: none !important; }
    .wijk .wijk-naam {
      padding: 12px 12px; } }

.wijk.wijk1 {
  border-left: 30px solid #009CBD; }
  .wijk.wijk1 .straat thead {
    background-color: #ccf6ff; }

.wijk.wijk2 {
  border-left: 30px solid #ED7102; }
  .wijk.wijk2 .straat thead {
    background-color: #fec18a; }

.wijk.wijk3 {
  border-left: 30px solid #349A6D; }
  .wijk.wijk3 .straat thead {
    background-color: #8ed9b8; }

.wijk.wijk4 {
  border-left: 30px solid #A7358B; }
  .wijk.wijk4 .straat thead {
    background-color: #de97cd; }

.wijk.wijk5 {
  border-left: 30px solid #E50055; }
  .wijk.wijk5 .straat thead {
    background-color: #ff99bf; }

.wijk.wijk6 {
  border-left: 30px solid #003861; }
  .wijk.wijk6 .straat thead {
    background-color: #94d2ff; }

.wijk.wijk7 {
  border-left: 30px solid #FDC300; }
  .wijk.wijk7 .straat thead {
    background-color: #ffe797; }

.optionscreen {
  margin-bottom: 48px; }
  .optionscreen .page-title {
    color: #0057A3;
    padding-bottom: 12px;
    padding-right: 45px; }
  .optionscreen h6 {
    margin: 0;
    padding-right: 45px; }
  .optionscreen .go-right {
    display: flex;
    justify-content: flex-end; }

.straatresult table {
  margin: 0;
  border-collapse: collapse;
  width: 100%; }
  .straatresult table tr td:first-of-type {
    padding: 0 12px 0 30px; }
  .straatresult table thead tr {
    height: 45px; }
    .straatresult table thead tr td {
      vertical-align: bottom; }
    .straatresult table thead tr h6 {
      margin: 0;
      padding: 12px 30px 6px 0; }
    .straatresult table thead tr .label {
      padding-bottom: 6px; }
  .straatresult table tbody tr {
    height: 30px; }

@media (max-width: 400px) {
  .straatresult table tr td:first-of-type {
    padding: 6px 12px 6px 12px; } }

.wijkresult {
  background-color: #fff;
  border-left: none;
  overflow: hidden;
  padding-bottom: 12px;
  margin-bottom: 48px;
  border-radius: 3px 3px 12px 3px; }
  .wijkresult .kopwijk {
    margin: 0;
    padding: 12px 30px; }

@media (max-width: 430px) {
  .wijkresult .kopwijk {
    padding: 6px 12px 6px 12px; } }

.wijkresult.wijk1 .kopwijk {
  background-color: #ccf6ff; }

.wijkresult.wijk2 .kopwijk {
  background-color: #fec18a; }

.wijkresult.wijk3 .kopwijk {
  background-color: #8ed9b8; }

.wijkresult.wijk4 .kopwijk {
  background-color: #de97cd; }

.wijkresult.wijk5 .kopwijk {
  background-color: #ff99bf; }

.wijkresult.wijk6 .kopwijk {
  background-color: #94d2ff; }

.wijkresult.wijk7 .kopwijk {
  background-color: #ffe797; }

.mailmodal {
  pointer-events: none; }
  .mailmodal .overlay {
    z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    background-color: #333;
    transition: opacity 0.3s ease; }
  .mailmodal .form {
    z-index: 101;
    max-height: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: hidden;
    max-width: 0; }
    .mailmodal .form .input-wrapper {
      display: inline-block;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
      padding: 0;
      border-radius: 9px;
      height: 46px; }
    .mailmodal .form input {
      width: 450px;
      max-width: 80vw;
      height: 60px;
      border: none;
      font-size: 18.66px;
      padding-left: 22px;
      border-radius: 3px 3px 9px 3px;
      background-color: #fff;
      border: 2px solid #fff;
      box-sizing: border-box; }
    .mailmodal .form input::-webkit-input-placeholder {
      color: #bbb; }
    .mailmodal .form input::placeholder {
      color: #bbb; }
    .mailmodal .form button {
      margin: 24px auto; }
    .mailmodal .form .mail {
      margin-right: 6px; }
    .mailmodal .form .melding {
      color: #fff;
      padding-left: 12px; }

.mailmodal.open {
  pointer-events: all; }
  .mailmodal.open .overlay {
    opacity: 0.8; }
  .mailmodal.open .form {
    max-height: 80px;
    max-width: 100%;
    overflow: visible; }

.exitscreen {
  position: relative; }
  .exitscreen .kop {
    color: #0057A3;
    margin-bottom: 48px; }
  .exitscreen .top {
    position: relative;
    margin-bottom: 48px; }
  .exitscreen .top:after {
    content: "";
    display: table;
    clear: both; }
  .exitscreen .kader {
    width: 60%;
    float: left;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 3px 3px 12px 3px;
    padding: 30px; }
    .exitscreen .kader p {
      margin: 0; }
    .exitscreen .kader p:first-of-type {
      margin-bottom: 12px; }
    .exitscreen .kader .advies {
      font-weight: 600; }
    .exitscreen .kader a {
      color: #0057A3; }
  .exitscreen .buttons {
    margin: 0;
    width: 40%;
    float: right;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    align-items: flex-end; }
    .exitscreen .buttons button {
      width: 200px; }
  .exitscreen .wijken {
    position: relative;
    overflow: hidden;
    margin-bottom: 48px; }

@page {
  size: A4;
  margin: 1.5cm; }

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    background-color: #fff !important;
    background: #fff !important; }
  .exitscreen .header,
  .exitscreen .buttons,
  .exitscreen .sharebuttons {
    display: none; }
  .exitscreen .kop {
    margin-top: 0px;
    color: #333; }
  .exitscreen .kader {
    width: 100%; }
  .exitscreen .wijken,
  .exitscreen .kader {
    border: 1px solid #d9d9d9; }
  .exitscreen table tr td,
  .exitscreen table tr th {
    page-break-inside: avoid; } }

@media (max-width: 720px) {
  .exitscreen .kader {
    width: 100%;
    margin-bottom: 48px; }
  .exitscreen .buttons {
    width: 100%;
    float: none;
    align-items: center; } }

@media (max-width: 430px) {
  .exitscreen .buttons button {
    width: calc(100% - 42px); } }

@font-face {
  font-family: 'TheSerif E2s';
  src: url(../../static/media/TheSerifE2s-8_Bold.35b94b5d.otf); }

body {
  margin: 0;
  padding: 0;
  background-color: #EDF1F2;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  body .wrapper {
    max-width: 860px;
    margin: 0 auto;
    margin-top: 160px; }
  body h1,
  body h2,
  body h3,
  body h4,
  body h5 {
    font-family: 'TheSerif E2s', serif;
    margin: 0; }
  body h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px; }
  body h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px; }
  body h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px; }
  body h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px; }
  body h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px; }
  body h6 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0; }
  body p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0; }
  body a {
    color: #fff;
    text-decoration: none; }

@media (max-width: 920px) {
  body .wrapper {
    max-width: calc(100vw - 60px); } }

