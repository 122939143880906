@import '../../styles/colors.scss';

.sharebuttons {
	transition: all 0.3s ease-in-out;
	position: fixed;
	top: 168px;
	right: -186px;
	height: 45px;
	background-color: $kleur-primair-abp-petrol;
	border-radius: 6px 0 0 6px;
	z-index:3;
	box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
	span {
		display: inline-block;
		width: 45px;
		height: 45px;
		cursor: pointer;
	}
	.mail {
		rect {
			fill: $kleur-primair-abp-petrol;
		}
		polyline {
			fill: $kleur-primair-abp-petrol;
       stroke: #fff;
       stroke-width: 2px;
   }
	}
	.socialbutton {
		background-color: #fff;
		border-left: 1px solid $kleur-primair-abp-petrol;
		.icon {
			transition: all 0.1s ease-in-out;
		}
	}
	.socialbutton:hover {
		.icon {
			transform: scale(1.2);
		}
	}
}
.sharebuttons.open {
	right: 0px;
}
