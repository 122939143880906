@import '../../styles/colors.scss';

.straat {
    color: $grijstint-zwart;

    table {
        margin: 0;
        border-collapse: collapse;
        width: 100%;

        tr {
            height: 80px;
            overflow: hidden;
            td:first-of-type {
                padding: 6px 12px 6px 30px;
            }
        }
        thead {
            tr {
                td {
                    vertical-align: middle;
                }
                h6 {
                    margin: 0;
                    padding: 12px 30px 6px 0;
                }
                .label {
                    padding: 6px 0;
                }
            }
        }
        tbody {
            background-color: $grijstint-wit;

            tr {
                td {
                    border-top: 1px solid $grijstint-subtielgrijs;
                    border-bottom: 1px solid $grijstint-subtielgrijs;
                }
            }
            tr:last-of-type {
                td {
                    border-bottom: none;
                }
            }
        }
        .label {
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            width: 100px;
            position: relative;
        }

        label {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0px;
            right: 0px;
            width: 100%;
            height: 80px;
                input[type="radio"] {
                opacity: 0;
                padding: 15px;
            }
        }
    }
}

@media (max-width: 720px) {
    .straat {
        table {
            tr {
                td:first-of-type {
                    padding: 6px 0px 6px 12px;
                    width: 50%;
                }
            }
            thead {
                tr {
                    h6 {
                        padding: 12px 0 6px 0;
                    }
                }
            }
            .label {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 400px) {
    .straat {
        table {
            thead {
                tr {
                    h6 {
                        font-size: 14px;
                    }
                }
            }
            .label {
                font-size: 14px;
            }
            td {
                font-size: 14px;
            }
        }
    }
}
