@import '../../styles/colors.scss';

.exitscreen {
    position: relative;

    .kop {
        color: $kleur-primair-abp-blauw;
        margin-bottom: 48px;
    }
    .top {
        position: relative;
        margin-bottom: 48px;
    }
    .top:after {
        content: "";
        display: table;
        clear: both;
    }
    .kader {
        width: 60%;
        float: left;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 3px 3px 12px 3px;
        padding: 30px;

        p {
            margin: 0;
        }
        p:first-of-type {
            margin-bottom: 12px;
        }
        .advies {
            font-weight: 600;
        }
        a {
            color: $kleur-primair-abp-blauw;
        }
    }
    .buttons {
        margin: 0;
        width: 40%;
        float: right;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;

        button {
            width: 200px;
        }
    }
    .wijken {
        position: relative;
        overflow: hidden;
        margin-bottom: 48px;
    }
}

@page {
    size: A4;
    margin: 1.5cm;
}

@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
        background-color: #fff !important;
        background: #fff !important;
    }
    .exitscreen {
        .header,
        .buttons,
        .sharebuttons {
            display: none;
        }
        .kop {
            margin-top: 0px;
            color: #333;
        }
        .kader {
            width: 100%;
        }
        .wijken,
        .kader {
            border: 1px solid #d9d9d9;
        }
        table tr td,
        table tr th {
            page-break-inside: avoid;
        }
    }
}

@media (max-width: 720px) {
    .exitscreen {
        .kader {
            width: 100%;
            margin-bottom: 48px;
        }
        .buttons {
            width: 100%;
            float: none;
            align-items: center;
        }
    }
}

@media (max-width: 430px) {
    .exitscreen {
        .buttons {
            button {
                width: calc(100% - 42px);
            }
        }
    }
}
