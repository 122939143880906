@import '../../styles/colors.scss';
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 240px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px 3px 36px 3px;
    cursor: pointer;
    text-align: center;

    .card-title {
        min-height: 48px;
        padding: 24px;
        margin: 0;
        color: $kleur-primair-abp-blauw;
    }
    
    p {
        margin: 0 24px;
        color: $grijstint-zwartgrijs;
        height: 100%;
    }

    button {
        margin-bottom: 24px;
    }
}

@media (max-width: 430px) {
    .card {
        width: 100%;
        height: auto;

        .card-title {
            min-height: unset;
        }

        p {
            margin-bottom: 24px;
        }
    }
}
