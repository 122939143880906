@import '../../styles/colors.scss';

.wijk {
    background-color: #fff;
    border-radius: 3px 3px 36px 3px;
    border-left: 30px solid $grijstint-zwartgrijs;
    margin: 48px 0 0 0;
    color: $grijstint-zwartgrijs;
    overflow: hidden;

    .wijk-naam {
        margin: 0;
        padding: 12px 30px;
    }
}

.wijk:last-of-type {
    margin-bottom: 48px;
}


@media (max-width: 720px) {
    .wijk {
        border-left: none !important;
        
        .wijk-naam {
            padding: 12px 12px;
        }

    }
}

.wijk.wijk1 {
    border-left: 30px solid $kleur-primair-abp-petrol;
    .straat {
        thead {
            background-color: lighten($kleur-primair-abp-petrol, 53%);
        }
    }
}

.wijk.wijk2 {
    border-left: 30px solid $kleur-primair-abp-oranje;
    .straat {
        thead {
            background-color: lighten($kleur-primair-abp-oranje, 30%);
        }
    }
}

.wijk.wijk3 {
    border-left: 30px solid $kleur-secundair-groen;
    .straat {
        thead {
            background-color: lighten($kleur-secundair-groen, 30%);
        }
    }
}

.wijk.wijk4 {
    border-left: 30px solid $kleur-secundair-paars;
    .straat {
        thead {
            background-color: lighten($kleur-secundair-paars, 30%);
        }
    }
}

.wijk.wijk5 {
    border-left: 30px solid $kleur-secundair-rood;
    .straat {
        thead {
            background-color: lighten($kleur-secundair-rood, 35%);
        }
    }
}

.wijk.wijk6 {
    border-left: 30px solid $kleur-primair-abp-donker-blauw;
    .straat {
        thead {
            background-color: lighten($kleur-primair-abp-donker-blauw, 60%);
        }
    }
}

.wijk.wijk7 {
    border-left: 30px solid $kleur-secundair-geel;
    .straat {
        thead {
            background-color: lighten($kleur-secundair-geel, 30%);
        }
    }
}
