$kleur-primair-abp-blauw: #0057A3;
$kleur-primair-abp-petrol: #009CBD;
$kleur-primair-abp-licht-blauw: #CCEBF1;
$kleur-primair-abp-oranje: #ED7102;
$kleur-primair-abp-donker-blauw: #003861;

$kleur-secundair-rood: #E50055;
$kleur-secundair-groen: #349A6D;
$kleur-secundair-paars: #A7358B;
$kleur-secundair-geel: #FDC300;
$kleur-secundair-licht-rood: #F8C4D7;
$kleur-secundair-licht-groen: #CBF0E0;
$kleur-secundair-licht-geel: #FEF1C6;

$grijstint-zwart: #000000;
$grijstint-zwartgrijs: #282D32;
$grijstint-donkergrijs: #585E62;
$grijstint-grijs: #ACAEB0;
$grijstint-subtielgrijs: #E2E5E9;
$grijstint-witgrijs: #EDF1F2;
$grijstint-wit: #FFFFFF;