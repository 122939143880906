@import '../../styles/colors.scss';

.wijkresult {
    background-color: #fff;
    border-left: none;
    overflow: hidden;
    padding-bottom: 12px;
    margin-bottom: 48px;
    border-radius: 3px 3px 12px 3px;

    .kopwijk {
        margin: 0;
        padding: 12px 30px;
    }
}

@media (max-width: 430px) {
    .wijkresult {
        .kopwijk {
            padding: 6px 12px 6px 12px;
        }
    }
}

.wijkresult.wijk1 {
    .kopwijk {
        background-color: lighten($kleur-primair-abp-petrol, 53%);
    }
}

.wijkresult.wijk2 {
    .kopwijk {
        background-color: lighten($kleur-primair-abp-oranje, 30%);
    }
}

.wijkresult.wijk3 {
    .kopwijk {
        background-color: lighten($kleur-secundair-groen, 30%);
    }
}

.wijkresult.wijk4 {
    .kopwijk {
        background-color: lighten($kleur-secundair-paars, 30%);
    }
}

.wijkresult.wijk5 {
    .kopwijk {
        background-color: lighten($kleur-secundair-rood, 35%);
    }
}

.wijkresult.wijk6 {
    .kopwijk {
        background-color: lighten($kleur-primair-abp-donker-blauw, 60%);
    }
}

.wijkresult.wijk7 {
    .kopwijk {
        background-color: lighten($kleur-secundair-geel, 30%);
    }
}