@import '../../styles/colors.scss';

$height: 100px;
//* large
$logo-lg: 48px;
$outer-edge-lg: 60px;
$sides-lg: 160px;
//* medium
$logo-md: 24px;
$outer-edge-md: 36px;
$sides-md: 80px;
//* small
$logo-sm: 12px;
$sides-sm: 60px;

.header {
    position: fixed;
    min-height: $height;
    z-index: 2;
    top: 0;
    width: 100%;
    background-color: $grijstint-wit;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.03);
    border-radius: 0px 0px 32px 4px;

    display: grid;
    grid-template-columns: $outer-edge-lg $sides-lg auto $outer-edge-lg;
    grid-template-rows: $height 12px;

    a {
        text-decoration: none;
        align-self: center;
    }

    .title {
        text-align: center;
        padding: 0;
        color: $grijstint-zwart;
        margin-right: $sides-lg;
    }

    .backbutton {
        width: $outer-edge-lg;
        cursor: pointer;
        align-self: center;

        .pijl {
            width: $outer-edge-lg;
            height: $outer-edge-lg;
        }
    }

    .logo-abp {
        fill: #fff;
        height: $logo-lg;
        width: $sides-lg;
        align-self: center;
    }

    .infobutton {
        width: $outer-edge-lg;
        cursor: pointer;
        align-self: center;

        .info {
            width: $outer-edge-lg;
            height: $outer-edge-lg;
            fill: $kleur-primair-abp-petrol;
        }
    }

    .icon {
        transition: transform 0.3s ease;
    }
    .icon:hover {
        transform: scale(1.2)
    }

    .stepper {
        width: 100%;
        height: 12px;
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 2;
        background-color: $grijstint-subtielgrijs;
        background: linear-gradient(90deg, rgba(226,229,233,1) 0%, rgba(226,229,233,1) 66%, rgba(255,255,255,0) 100%);

        .step {
            float: left;
            width: 33.333333%;
            height: 12px;
            background-color: $kleur-primair-abp-licht-blauw;
            border-right: 1px solid $grijstint-grijs;
            box-sizing: border-box;
        }
        .step:last-of-type {
            border-right: none;
        }
        .last {
            background-color: $kleur-primair-abp-licht-blauw;
            background: linear-gradient(90deg, rgba(204,235,241,1) 0%, rgba(255,255,255,0) 100%);
        }
    }
}

@media (max-width: 700px) {
    .header {
        grid-template-columns: $outer-edge-md $sides-md auto $outer-edge-md;

        .title {
            margin-right: $sides-md;
        }
        h2 {
            margin-top: 21px;
            font-size: 18px;
        }
        .logo-abp {
            height: $logo-md;
            width: $sides-md;
        }
        .backbutton {
            width: $outer-edge-md;
    
            .pijl {
                width: $outer-edge-md;
                height: $outer-edge-md;
            }
        }
        .infobutton {
            width: $outer-edge-md;
    
            .info {
                width: $outer-edge-md;
                height: $outer-edge-md;
            }
        }
    }
}

@media (max-width: 430px) {
    .header {
        grid-template-columns: $outer-edge-md $sides-sm auto $outer-edge-md;
        
        .title {
            margin-right: $sides-sm;
        }
        h2 {
            margin-top: 24px;
            font-size: 15px;
        }
        .logo-abp {
            height: $logo-sm;
            width: $sides-sm;
        }
    }
}
